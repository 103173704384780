import React, { useState, useEffect } from "react"
import Button from "react-bootstrap/Button"
import {ToastContainer, toast} from 'react-toastify';
import Table from "react-bootstrap/Table"
import {
  ConsultaRnpiF,
  ConsultaRnpiPDFF,
} from "../../state/actions/Certificados/RnpiActions"
import { useSelector, useDispatch } from "react-redux"
import NoInfoComponent from '../../Utils/NoInfo'; 
import moment from 'moment';

const { format } = require("../../Utils/ValidateRut")

const CertificadosRnpiComponent = () => {
  const [Descargando, setDescargando] = useState(false)
  const dispatch = useDispatch()

  const Rnpi = useSelector(state => state.Rnpi.Rnpi)
  const PDFRnpi = useSelector(state => state.Rnpi.PDFRnpi)

  useEffect(() => {
    if (Rnpi.length === 0) {
      const ConsultaRnpi = () => dispatch(ConsultaRnpiF())
      ConsultaRnpi()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const DescargaCertificado = () => {
    
    if( PDFRnpi !== "" && PDFRnpi !== null && PDFRnpi.length !== 0){
    const link = document.createElement("a")
    link.setAttribute(
      "href",
      "data:application/octet-stream;base64," +
        encodeURIComponent(PDFRnpi[0].pdf_text)
    )
    link.setAttribute("download", "Prestador.pdf")
    link.style.display = "none"
    document.body.appendChild(link)
    link.click()
    
    toast.success("Descarga exitosa.")

  }else{

    const ConsultaDescargaCertificado = () =>
    dispatch(ConsultaRnpiPDFF( setDescargando, PDFRnpi))
    ConsultaDescargaCertificado()
  }

  }

  return (
    <>
    <ToastContainer closeOnClick={true} pauseOnHover={true} />
      
        <div className="contenedor">

        {Rnpi !== undefined && Rnpi.length !== 0 ?
          <>
          <h1 className="font-weight-bold text-center titulo-antecedentes-ss">
          Mi Información como profesional de la salud
          </h1>

          <div className="table-responsive ">

              <>
                <Table striped bordered hover size="sm">
                  <tbody>
                    <tr>
                      <th scope="row">Nombre Completo: </th>
                      <td>{Rnpi.nombreCompleto}</td>
                    </tr>

                    <tr>
                      <th scope="row">Rut: </th>
                      <td>{format(Rnpi.rutPres + Rnpi.dvPres)}</td>
                    </tr>

                    <tr>
                      <th scope="row">N° de Registro: </th>
                      <td>{Rnpi.nroRegistro}</td>
                    </tr>

                    <tr>
                      <th scope="row">Fecha de Inscripción:</th>
                      <td>{moment(Rnpi.fechaRegistro.split('/')[2]+Rnpi.fechaRegistro.split('/')[1]+Rnpi.fechaRegistro.split('/')[0]).format('DD-MM-YYYY')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Región de Trabajo:</th>
                      <td>{Rnpi.regionTrabajo}</td>
                    </tr>
                  </tbody>
                </Table>
                {Rnpi !== undefined && Rnpi.length !== 0
                  ? Rnpi.antecedentes.map((dato, i) => (
                    <>
                      <Table
                        striped
                        bordered
                        hover
                        size="sm"
                        className="my-4"
                        key={i}
                      >
                        <tbody>
                          <tr>
                            <th scope="row">
                              Antecedente del Título o Habilitación profesional:{" "}
                              {dato.codAntecedente}
                            </th>
                          </tr>

                          <tr key={i}>
                            <td className="glosa-certificados-ss">
                              {dato.glosa}

                              {dato.tipoAntecedente === "E" ? (
                                <p className="pt-3">
                                  <b>Fecha Expiración: </b>
                                  {dato.fechaFinCertif !== "No Informada" ? moment(dato.fechaFinCertif.split('/')[2]+dato.fechaFinCertif.split('/')[1]+dato.fechaFinCertif.split('/')[0]).format('DD-MM-YYYY') : dato.fechaFinCertif}
                                </p>
                              ) : null}
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      </>
                    ))
                  : null}
                  
                   <Button
                      className="mx-auto my-auto btn-notif d-flex text-white"
                      size="sm"
                      onClick={() => DescargaCertificado()}
                      disabled={Descargando}
                      >
                      Descargar
                      {Descargando && 
                      <div className="spinner-border spinner-border-sm ml-2" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      }
                      </Button>

              </>
          
          </div>

          </>
          :
          <NoInfoComponent/>
          }

       
        </div>
    </>
  )
}

export default CertificadosRnpiComponent
