import React from 'react';
import LayoutProvider from "../../../components/Layout"
import CertificadosRnpiComponent from "../../../components/CertificadosComponents/CertificadosRnpi"

const CertificadosReclamo = ({location}) => {
    return ( 

        <LayoutProvider title="Certificados RNPI" location={location}>

        <CertificadosRnpiComponent />

        </LayoutProvider>



     );
}
 
export default CertificadosReclamo;